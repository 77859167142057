import GATSBY_COMPILED_MDX from "/opt/buildhome/repo/data/blog/second-post.mdx";
import * as React from 'react';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import {graphql} from "gatsby";
export const query = graphql`
  query ($id: String) {
      mdx(id: {eq: $id}) {
          frontmatter {
              title
              date(formatString: "MMMM D, YYYY")
          }
      }
  }
`;
const BlogPost = ({data, children}) => {
  return React.createElement(Layout, {
    pageTitle: data.mdx.frontmatter.title
  }, React.createElement("p", null, data.mdx.frontmatter.date), children);
};
export const Head = ({data}) => React.createElement(Seo, {
  title: data.mdx.frontmatter.title
});
BlogPost
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPost, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
